@import "./styles.css";
@import "./ovroomFormPage.css";

.verify-code-heading-container {
  grid-row: 1;
  grid-column: 2;
}
.verify-code-heading-container-mobile {
  grid-row: 1;
  grid-column: 2;
}

.verify-code-title-container {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.verify-code-title-container-mobile {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 1.5rem;
  width: 100%;
}

.verify-code-instructions {
  margin-top: 1rem;
}

.verify-code-form {
  grid-row: 2;
  grid-column: 2;
  width: 100%;
}

.resendContainer {
  grid-row: 3;
  grid-column: 2;
  display: flex;
  flex-direction: row;
}

.resend-text {
  font-size: 0.9rem;
}

.resend-text-link {
  margin-left: 0.5rem;
  text-decoration: underline;
  color: var(--color-neelo);
}

.resend-button {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 1px;
  font-size: 0.8 rem;
  background-color: var(--color-safed);
}
