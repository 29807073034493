@import "./styles.css";
@import "./ovroomFormPage.css";
@import "./app.css";

.view-spinner-container {
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.view-page-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: var(--color-greyoda);
  font-weight: bold;
}

.view-section-title {
  margin-top: 1rem;
  font-size: 1.25rem;
  color: var(--color-greyoda);
  font-weight: bold;
}

.view-title-container {
  display: grid;
  grid-template-columns: 70fr auto;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.view-content-container {
  margin-top: 1rem;
}

.view-title-text {
  grid-row: 1;
  grid-column: 1;
  color: var(--color-primary);
  font-size: 1.25rem;
  font-weight: bolder;
}

.view-title-button {
  grid-row: 1;
  grid-column: 2;
}

.view-title-button-text {
  text-decoration: none;
}

.view-item-name-text {
  color: var(--color-primary);
  font-size: 0.8rem;
}

.view-item-name-text-accent {
  color: var(--color-accent);
  font-size: 0.8rem;
}

.view-item-content-text {
  color: var(--color-greyoda);
}

.view-emphasis-text {
  color: var(--color-primary);
  size: 0.8rem;
}

.view-price-table-container {
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 1px;
  column-gap: 1px;
}

.view-price-table-header-text {
  font-weight: bold;
  color: var(--color-greyoda);
}

.view-highlights-checkmark-text {
  color: var(--color-neelo);
}

.edit-button-with-warning-container {
  display: flex;
  flex-direction: column;
}
