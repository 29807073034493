@import "./styles.css";

.section-card {
  margin: 0.5rem;
}

.section-card-body-text {
  color: black;
}

.section-card-image {
  object-fit: cover;
}
