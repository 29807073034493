@import "./styles.css";

.section3-container {
  padding-top: 20px;
  background-color: var(--color-greyoda);
}

.section3-container-mobile {
  padding-right: 0 !important;
  padding-bottom: 20px;
}

.section3-heading {
  color: var(--color-safed);
  font-weight: 700;
}

.section3-dotlist-class button {
  border-color: var(--color-safed);
}
