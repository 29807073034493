:root {
  --color-kesudo: #ff5050;
  /* --color-neelo: #00a0a0; */
  /* --color-neelo: #326ef5; */
  --color-neelo: #295dcf;
  --color-chundadi: #ff2525;
  --color-greyo: #767676;
  --color-greyoda: #484848;
  --color-greyoli: #d2d2d2;
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1rem;
  color: var(--color-greyoda);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  color: var(--color-headings);
}

h1 {
  font-size: 7rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NavBarBackgroundBanner {
  background-color: rgb(255, 255, 255, 0.75);
}

.navbar {
  background-color: "transparent";
}

.clickable {
  cursor: pointer;
}
