@import "./styles.css";

.hero-image {
  background-image: url("../images/HeroBackground.jpg");
  height: 75vh;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-image-desktop {
  height: 85vh; /* Adjust height for desktops */
}

.hero-image-desktop-bigscreen {
  height: 95vh; /* Adjust heights for very large screens */
}

.search-container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}
