@import "styles.css";

.text-area-form-label {
  margin: 0;
  font-size: 0.9rem;
}

.text-area-form {
  width: 100%;
  border-radius: 5pt;
}

.text-area-form:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--color-greyoda);
}
