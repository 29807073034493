@import "./styles.css";

.tac-container {
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  width: 100vw;
  height: 100vh;
}

.tac-container-mobile {
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  width: 100vw;
  height: 100vh;
}
