@import "styles.css";

.search-box {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-safed);
  border-radius: 1rem;
}

.search-box-2 {
  display: inline-flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: var(--color-safed);
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100% !important;
}

.search-text {
  margin-bottom: 0;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 3rem;
  padding-bottom: 0.5rem;
  align-self: center;

  font-weight: 700;
  color: var(--color-greyoda);
}

.search-icon {
  padding-right: 0;
  padding-left: 3rem;
  align-self: center;

  color: var(--color-kesudo);
}
