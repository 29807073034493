@import "./styles.css";

.section3-card {
  margin: 0.5rem;
}

.section3-card-body {
  background-color: var(--color-greyoda);
}

.section3-card-body-text {
  color: var(--color-safed);
}

.section3-card-image {
  object-fit: cover;
}
