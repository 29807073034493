@import "./styles.css";

/********************
* Main containers of the page
* There are two ways to set up form:
* 1. With instructions - this option is identified below as 'option1' identifier for the CSS class names
*    In this option, we have the form instructions shown in the 2nd row below the header.
* 2. Without instructions - this option is identified below as 'opetion2' identifier for the CSS class names
*    In this option, we do not have instructions below the header.
********************/

.ovroom-form-container {
  display: grid;

  grid-template-rows: auto auto auto;
  grid-template-columns: auto 50% auto;
  row-gap: 0.25rem;
  /* align grid horizontally in center */
  justify-content: center;
  /* align the grid vertically in the center */
  align-content: center;

  /* align contents within the grid's cells in the center */
  /* justify-items: center; */
  align-items: center;

  width: 100vw;
  /* height: 100vh; */
}

.ovroom-form-container-mobile {
  display: grid;

  grid-template-rows: auto auto auto;
  grid-template-columns: auto 80% auto;
  row-gap: 0.25rem;
  /* align grid horizontally in center */
  justify-content: center;
  /* align the grid vertically in the center */
  align-content: center;

  /* align contents within the grid's cells in the center */
  justify-items: center;

  width: 100vw;
}

/********************
* Header container
********************/

.ovroom-form-header-container {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

.ovroom-form-header-container-mobile {
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 1.5rem;
  width: 100%;
}

/********************
* Header text style (title of the page)
********************/
.ovroom-form-page-heading {
  font-size: 2rem;
  color: var(--color-kesudo);
}
.ovroom-form-page-heading-mobile {
  font-size: 1rem;
  color: var(--color-kesudo);
  margin: 0;
}

/********************
* Section heading text style
********************/
.ovroom-form-section-heading {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--color-kesudo);
  font-size: 1.25rem;
}

.ovroom-form-section-heading-mobile {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: var(--color-kesudo);
  font-size: 1rem;
}

/********************
* 'option1'
* Second level instruction paragraph text container
********************/
.ovroom-form-instruction-container-option1 {
  grid-row: 2;
  grid-column: 2;
  width: 100%;
  margin-top: 1rem;
}

/********************
* 'option1'
* Form elements
* This is where all input elements and the submit buttons live.
********************/
.ovroom-form-elements-container-option1 {
  grid-row: 3;
  grid-column: 2;
  width: 100%;
  margin-top: 1rem;
}

/********************
* 'option2'
* Form elements
* This is where all input elements and the submit buttons live.
********************/
.ovroom-form-elements-container-option2 {
  grid-row: 2;
  grid-column: 2;
  width: 100%;
}

/********************
* .price_selection-container {

* 'option3'
* Form elements
* Option 3 allows the user to have button-group to select between choices.
* It also has instructions container below the button-group to guide the user properly in how to make selection.
* It finally has the form which the user fills out and submits.
********************/
.ovroom-form-elements-container-option3 {
  grid-row: 2;
  grid-column: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  margin-top: 1rem;
}

/* .price-list-instruction-container { */
.ovroom-form-instructions-container-option3 {
  grid-row: 3;
  grid-column: 2;
  margin-bottom: 1rem;
  width: 100%;
}

/*.price-list-container { */
.ovroom-form-container-option3 {
  grid-row: 4;
  grid-column: 2;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
}

/********************
* The submit button of the form
********************/
.ovroom-form-submit-button {
  background-color: var(--color-kesudo);
  width: 100%;
  height: 2.5rem;
}

/********************
* The logo images
********************/
.logo-image {
  width: 3rem;
  height: 3rem;
}

.logo-image-mobile {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

/********************
* The button group
********************/
.ovroom-buttongroup-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ovroom-buttongroup-button {
  margin: 1pt;
}

/********************
* The bottom filler
********************/
.vertical-filler-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 1pt;
}

/********************
* Style of date-picker container (for "react-datepicker" npm package component)
********************/
.ovroom-date-picker-container-style {
  border: 1px solid var(--color-greyoda);
  border-radius: 5pt;
  padding-left: 0.5rem;
  width: 100%;
  height: 2rem;
}

/********************
* Customize file input field (file selector)
* We hide the 'input' element and style the 'lable' element surrounding it
********************/

/* Hide input element */
input[type="file"] {
  display: none;
}
/* Customize label element */
.ovroom-label-for-input-file-selector {
  display: inline-block;
  cursor: pointer;
  color: var(--color-primary);
}
