@import "styles.css";

.checkbox-form-label {
  margin: 0;
  font-size: 0.9rem;
}

.checkbox-form {
  border-color: var(--color-greyoda);

  /* background-color: var(--color-kesudo); */
  /* border: 1px solid #cacece; */
}

.checkbox-form:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--color-greyoda);
}
