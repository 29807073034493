@import "./styles.css";
@import "./ovroomFormPage.css";
@import "./app.css";

/************************************
* Grid of tour
************************************/

.operatorTour-search-box {
  display: inline-flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 0.25rem;
  border-width: 1pt;
  border-color: var(--color-primary);
  width: 70%;
}

.operatorTour-search-text {
  margin-bottom: 0;
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
  align-self: center;
  font-weight: light;
  color: var(--color-primary);
}

.operatorTour-search-icon {
  padding-right: 0;
  padding-left: 1rem;
  align-self: center;
  color: var(--color-primary);
}

.operatorTour-add-button {
  /* width: 30%;
  margin-left: 1rem; */
}

.operatorTour-tour-card-image-style {
  object-fit: "cover";
}

.operator-edit-tour-card-image-style {
  width: 200;
  height: 200;
  object-fit: "cover";
}

.operator-edit-tour-card-image-style-mobile {
  width: 100;
  height: 100;
  object-fit: "cover";
}

.operator-edit-tour-image-buttons-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.operator-edit-tour-section-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.operator-edit-tour-container-card {
  width: 100fr;
}

.operator-navigation-styles {
  margin: 1rem 0 0rem 0;
  --bs-breadcrumb-divider: "|";
}

.operator-navigation-inactive-link-style {
  color: var(--color-greyoda);
  font-size: 1rem;
  text-decoration: none;
}

.operator-navigation-active-link-style {
  color: var(--color-primary);
  font-size: 1rem;
  font-weight: bolder;
  text-decoration: none;
}

.operator-navigation-active-link-style-level2 {
  color: var(--color-neelo);
  font-size: 1rem;
  font-weight: bolder;
  text-decoration: none;
}

.operator-navigation-horizontal-line-container {
  padding-bottom: 0rem;
  width: 100%;
  height: 1pt;
}

.operator-subsection-title {
  font-size: 1.5rem;
}
