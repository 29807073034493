@import "./styles.css";

/* .container {
  padding-right: 0 !important;
} */

.section2-container {
  margin-top: 20px;
}

.section2-container-mobile {
  padding-right: 0 !important;
  padding-bottom: 20px;
}

.section2-heading {
  color: var(--color-greyoda);
  font-weight: 700;
}

.section2-dotlist-class button {
  border-color: var(--color-greyoda);
}

.react-multi-carousel-dot--active button {
  background: #ff5050;
}
