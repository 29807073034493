@import "./styles.css";
@import "./ovroomFormPage.css";

.existing-detail-container {
  padding: 1rem;
  margin: 0.25rem;
  border: 1px solid var(--color-greyoli);
  border-radius: 5px;
}

.existing-detail-removebutton-container {
  display: flex;
  flex-direction: row-reverse;
  /* justify-content: space-between; */
}

.new-detail-container {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  row-gap: 0.25rem;
}

.detail-title-text-style {
  font-weight: bold;
}
