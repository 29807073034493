@import "./styles.css";

.section5-container {
  margin: 20px 20px;
  display: grid;
  grid-template-columns: repeat(2, 50fr);
  justify-items: center;
  align-items: center;
}

.section5-container-mobile {
  margin: 20px 20px !important;
  display: grid;
  grid-template-rows: repeat(2, 50fr);
  justify-items: center;
  align-items: center;
}

.section5-menu {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.section5-menu-text {
  color: var(--color-greyoda);
  font-size: 1rem;
}

.section5-copyright {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.section5-copyright-text {
  margin-bottom: 0px;
  color: var(--color-greyoda);
  font-size: 1rem;
}

.section5-footer1 {
  padding-right: 10px;
  padding-left: 10px;
  border-style: solid;
  border-right-width: 1px;
  border-left-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-color: var(--color-greyoda);
}

.section5-footer2 {
  padding-right: 10px;
  padding-left: 10px;
}
