@import "styles.css";

.brand-container {
  flex-grow: 1;
}

#ovroomNavBarContentNotLoggedIn {
  flex-grow: 0;
}

#ovroomNavBarContentLoggedIn {
  flex-grow: 1;
}

.logo-text-desktop {
  font-size: 1rem;
  font-family: Helvetica;
  font-weight: bold;
  color: var(--color-primary);
}

.logo-text-safed-desktop {
  font-size: 1rem;
  font-family: Helvetica;
  font-weight: bold;
  color: var(--color-safed);
}

.logo-image-desktop {
  padding-right: 0.5rem;
  height: 2rem;
}

/* .logo-text-mobile {
  font-style: 2rem;
  font-family: Helvetica;
  color: var(--color-primary);
}

.logo-text-safed-mobile {
  font-style: 2rem;
  font-family: Helvetica;
  color: var(--color-safed);
} */

.logo-image-mobile {
  padding-right: 0.5rem;
  height: 1.5rem;
}

/* .toggler-icon-desktop {
  font-size: 2rem;
  font-weight: 100;
  color: var(--color-primary);
}

.toggler-icon-safed-desktop {
  font-size: 2rem;
  font-weight: 100;
  color: var(--color-safed);
}

.toggler-icon-mobile {
  font-size: 1.5rem;
  font-weight: lighter;
  color: var(--color-primary);
}

.toggler-icon-safed-mobile {
  font-size: 1.5rem;
  font-weight: lighter;
  color: var(--color-safed);
} */

.navigation-text-desktop {
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-primary) !important;
}

.navigation-text-safed-desktop {
  font-size: 1rem;
  font-weight: 500;
  color: var(--color-safed) !important;
}

.navigation-text-active {
  font-weight: bold;
  color: var(--color-primary) !important;
}

.navigation-text-safed-active {
  font-weight: bold;
  color: var(--color-primary) !important;
}

/* .navitation-text-mobile {
  font-size: 0.75rem;
  font-weight: lighter;
  color: var(--color-primary);
}

.navitation-text-safed-mobile {
  font-size: 0.75rem;
  font-weight: lighter;
  color: var(--color-safed);
} */

.profile-text {
  font-size: 1.25rem;
}

.navbar-badge {
  float: right;
  margin-bottom: -5rem;
  /* padding-bottom: 0.25rem !important; */
  padding-top: 0.01rem;
  background-color: var(--color-kesudo) !important;
}

.navbar-badge-mobile {
  /* float: right; */
  /* padding-bottom: 0.25rem !important; */
  padding-top: 0.01rem;
  margin-left: 00.25rem;
  background-color: var(--color-kesudo) !important;
}

#logout-label {
  color: var(--color-kesudo);
}

.dropdown-menu {
  min-width: 0;
}
.dropdown-menu .dropdown-item {
  white-space: normal;
}
