@import "./styles.css";
@import "./ovroomFormPage.css";

/**********************
* Style 1
* ********************/
.app-style1-container {
  display: grid;
  grid-template-rows: 10fr auto 10fr;
  grid-template-columns: 100fr;
  grid-template-areas:
    "header"
    "main"
    "footer";
  width: 100fr;
  height: 100fr;
  justify-items: center;
  align-items: center;
}

.app-style1-header-container-placement {
  grid-area: header;
  width: 100%;
}

.app-style1-main-container-placement {
  grid-area: main;
  width: 100%;
}

.app-style1-footer-container-placement {
  grid-area: footer;
  width: 100%;
}

.app-main-area-flex-layout {
  display: flex;
  flex-direction: column;
  width: 100fr;
  padding: 0 1rem 0 1rem;
}

.app-main-area-top-bar1 {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.app-button-group-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  margin-top: 1rem;
}
/************************************
* Display cards
************************************/

/* Style 1 */
.app-card-group-mobile {
  display: grid;
  grid-template-columns: auto;
  gap: 10px;
}
.app-card-group {
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  grid-template-rows: 30fr;
  gap: 10px;
  justify-items: start;
}
.app-card-style {
  width: 30fr;
  margin: 1rem;
}

/* Style 2 */
.app-card-group-style2-mobile {
  display: grid;
  grid-template-columns: auto;
  gap: 5px;
}
.app-card-group-style2 {
  display: grid;
  grid-template-columns: 20fr 20fr 20fr 20fr 20fr;
  grid-template-rows: 30fr;
  gap: 5px;
  justify-items: start;
}
