@import "normalize.css";

:root {
  --color-primary: #ff5050;
  /* --color-accent: #00a0a0; */
  --color-accent: #326ef5;
  --color-chundadi: #ff2525;
  --color-headings: #484848;
  --color-body: #918ca4;
  --color-kesudo: #ff5050;
  /* --color-neelo: #00a0a0; */
  --color-neelo: #326ef5;
  --color-chundadi: #ff2525;
  --color-greyo: #767676;
  --color-greyoda: #484848;
  --color-greyoli: #d2d2d2;
  --color-greyolili: #f2f2f2;
  --color-safed: #ffffff;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0 0 0 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  color: var(--color-headings);
}

h1 {
  font-size: 7rem;
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.clickable {
  cursor: pointer;
}

/* Hide Scrollbar */
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

/*************
* Button Primary
* "btn btn-primary and btn-outline-primary
*************/
.btn.btn-primary {
  background-color: var(--color-kesudo);
  border: hidden;
}

.btn.btn-primary:focus {
  background-color: var(--color-kesudo);
  border: hidden;
  outline: none;
  box-shadow: none;
}

.btn.btn-primary:disabled {
  background-color: var(--color-greyoli);
  border: hidden;
}

.btn.btn-outline-primary {
  color: var(--color-kesudo);
  border-color: var(--color-kesudo);
}
.btn.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--color-kesudo);
  border-color: var(--color-kesudo);
}
.btn.btn-outline-primary.focus,
.btn.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem var(--color-kesudo);
}
.btn.btn-outline-primary.disabled,
.btn.btn-outline-primary:disabled {
  color: var(--color-greyoli);
  background-color: transparent;
}
.btn.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--color-greyoli);
  border-color: var(--color-greyoli);
}
.btn.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--color-kesudo);
}

.btn.btn-primary-noborder {
  color: var(--color-kesudo);
}

.btn.btn-secondary-noborder {
  color: var(--color-neelo);
}

/********************
* The breadcrumb
********************/
ol.breadcrumb {
  margin-bottom: 0.25rem;
}

/********************
* The horizontal line
********************/
.horizontal-line-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  height: 1pt;
}

.horizontal-line-primary-color {
  margin: 0;
  color: var(--color-kesudo);
  background-color: var(--color-kesudo);
}

.horizontal-line-greyoli-color {
  margin: 0;
  color: var(--color-greyoli);
  background-color: var(--color-greyoli);
}

.horizontal-line-greyolili-color {
  margin: 0;
  color: var(--color-greyolili);
  background-color: var(--color-greyolili);
}

.horizontal-line-greyo-color {
  margin: 0;
  color: var(--color-greyo);
  background-color: var(--color-greyo);
}

.horizontal-line-greyoda-color {
  margin: 0;
  color: var(--color-greyoda);
  background-color: var(--color-greyoda);
}

/********************
* Pagination
********************/

.pagination > li.page-item > button.page-link {
  background-color: var(--color-safed);
  color: var(--color-primary);
  border: solid 1px var(--color-primary);
}

.pagination > li.page-item.active > button.page-link {
  color: var(--color-safed);
  background-color: var(--color-primary);
  color-scheme: var(--color-primary);
  border: solid 1px var(--color-primary);
}
