@import "./styles.css";
@import "./ovroomFormPage.css";

.unavailable-dates-and-delete-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1rem;
  margin: 0.25rem;
  border: 1px solid var(--color-greyoli);
  border-radius: 5px;
}

.unavailable-dates-container {
  display: flex;
  flex-direction: column;
}

.unavailable-date-picker-container {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  row-gap: 0.25rem;
}
