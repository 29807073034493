@import "./styles.css";

.privacy-container {
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  width: 100vw;
  /* height: 100vh; */
}

.privacy-container-mobile {
  margin-top: 2rem;
  margin-bottom: 2rem !important;
  width: 100vw;
  /* height: 100vh; */
}
