@import "./styles.css";

.section4-container {
  /* box-sizing: content-box; */
  width: 100vw !important;
  height: 400px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.section4-container-mobile {
  box-sizing: border-box !important;
  width: 90vw !important;
  height: 400px;
  margin-top: 5vw !important;
}

.section4-grid {
  height: 100% !important;

  display: grid;
  grid-template-rows: 100fr;
  grid-template-columns: repeat(2, 50fr);
  justify-items: center;
  align-items: center;

  background-image: url("../images/TourOperator.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.section4-grid-mobile {
  grid-template-rows: repeat(2, 50fr);
  grid-template-columns: 100fr;
  border-radius: 5px;
}

.section4-text-container {
}

.section4-text-mobile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section4-text {
  margin: 0;
  padding: 0;
  color: var(--color-safed);
  font-size: 1.5rem;
  font-weight: 600;
  align-self: center;
}

.section4-text-mobile {
}

.section4-button {
  background-color: var(--color-safed);
  color: var(--color-greyoda);
  border-color: var(--color-safed);
  border-width: 0;
  border-radius: 10px;
  font-weight: 500;
  padding: 0.3rem 0.5rem;
}

.section4-button-mobile {
  position: relative;
  top: 2rem;
}

.section4-button-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--color-safed);
  border-radius: 1rem;
}

.section4-button-box-mobile {
  position: relative;
  top: 2rem;
}

.section4-button-text {
  margin-bottom: 0;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  align-self: center;

  font-weight: 700;
  color: var(--color-greyoda);
}
