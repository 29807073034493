@import "./styles.css";

/* Customization of Toastify pop-ups */
.Toastify__toast-container {
  /* width: 320px; */
}
.Toastify__toast--default {
  background: var(--color-safed);
  color: var(--color-greyoda);
}
.Toastify__toast--info {
  background: var(--color-greyoda);
  color: var(--color-safed);
}
.Toastify__toast--success {
  background: var(--color-neelo);
  color: var(--color-safed);
}
.Toastify__toast--warning {
  background: var(--color-kesudo);
  color: var(--color-safed);
}
.Toastify__toast--error {
  background: var(--color-kesudo);
  color: var(--color-safed);
}
