@import "./styles.css";
@import "./ovroomFormPage.css";

.emailForm-container {
  grid-row: 2;
  grid-column: 2;
  width: 100%;
}

.emailForm-container-mobile {
  grid-row: 2;
  grid-column: 2;
  width: 100%;
}

.horizontal-line-container-1 {
  grid-row: 3;
  grid-column: 2;
  width: 100%;
  height: 1pt;
}

.phoneForm-container {
  grid-row: 4;
  grid-column: 2;
  width: 100%;
}

.phoneForm-container-mobile {
  grid-row: 4;
  grid-column: 2;
  width: 100%;
}

.login-label-text {
  font-size: 2rem;
  color: var(--color-kesudo);
}

.login-label-text-mobile {
  font-size: 1rem;
  color: var(--color-kesudo);
  margin: 0;
}

.horizontal-line-container-2 {
  grid-row: 5;
  grid-column: 2;
  width: 100%;
  height: 1pt;
}

.login-regisger-container {
  grid-row: 6;
  grid-column: 2;
  width: 100%;
}

.login-regisger-container-mobile {
  grid-row: 6;
  grid-column: 2;
  width: 100%;
}

.login-sub-text {
  margin-bottom: 0;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  font-size: 0.8rem;
}

.login-section-heading-container {
  display: flex;
  direction: row;
  padding: 0;
}

.login-section-heading-text {
  font-size: 1.5rem;
  color: var(--color-kesudo);
}

.login-section-heading-text-mobile {
  font-size: 1rem;
  color: var(--color-kesudo);
}
